.header-main {
	background: white;
	position: relative;
	z-index: @zindex-header;
}

.section--preheader {
	.compadv {
		&:extend(.list-unstyled all);
		display: flex;
		flex: 1 0 auto;
		font-weight: @font-weight-semibold;
		width: 100%;
		margin: 0 auto;
		//margin-bottom: -1rem;
		grid-column: ~"2/3";
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		&__item {
			flex: 1 0 auto;
			line-height: 1rem;
			text-align: center;
			&:not(:last-child) {
				border-right: 1px solid white;
			}
		}
	}
	.langswitch {
		grid-column: ~"3/4";
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.preheader__left {
		font-family: pacifico,cursive;
		padding-left: 55px;
		font-size: 1.1rem;
	}
	// prilis dlouhy text v EN verzi
	html[lang="en"] & {
		.preheader__left {
			padding-left: 20px;
			@media(max-width:1200px) {
				padding-left: 0;
			}
		}
	}
}

.section--header {
	.logo {
		padding: 0.75em 0;
		display: flex;
		align-items: center;
		&__wrapper {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			font-size: 1rem;
		}
		&__image {
			height: 2rem;
			@media (min-width: @screen-md-min) {
				height: 3.5rem;
			}
			@media print {
				height: 1.3cm;
			}
			width: auto;
			display: block;
		}
	}
}

.sectionheader__wrapper {
	display: flex;
	justify-content: space-between;
}

.header__claim {
	&:extend(.text--fancy);
	color: @brand-pink;
	font-size: 2rem;
	align-self: center;
	margin: 0;
	text-align: center;
	flex-grow: 2;
	display: none;
	@media (min-width: @screen-md-min) {
		display: block;
	}
	@media(max-width:1100px){
		font-size: 1.5rem;
	}
	a {
		&, &:link, &:visited, &:hover, &:active {
			color: @brand-pink;
			text-decoration: none;
		}
	}
}

.userinfo {
	display: flex;
	.dropdown-menu {
		.dropdown-menu-right;
		margin: 0;
		border-top: @space-xs solid @brand-pink;
		top: calc( 100% ~"-" @space-xs);
		// TODO: mely by mit dropdowny pevnou sirku? Maj byt na mobilu vubec?
		max-width: calc( 100vw ~"-" @grid-gutter-width);
		@media (min-width: @screen-md-min) {
			min-width: 15rem;
		}
	}

}

.usermenu__item, .basketinfo__item {
	&.dropdown.open {
		.dropdown-toggle {
			background: @brand-pink;
			color: white;
			&::before {
				color: white;
			}
			&:hover {
				background: @brand-pink;
				color: white;
				~ .dropdown-menu {
					border-top: @space-xs solid @brand-pink;
				}
			}
		}
	}
}


.usermenu {
	&:extend(.list-unstyled all);
	font-size: @font-size-smaller;
	margin: 0;
	display: flex;
	.section--header & {
		display: none;
		@media (min-width: @screen-md-min) {
			display: flex;
		}
	}
	.glyphicon-heart {
		color: @brand-pink;
		margin-right: 1ch;
	}
}

.usermenu__item {
	display: flex;
	> a:not(:hover):not(:focus)::after {
		content: "|";
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	> a {
		.space-inset-base;
		flex-grow: 2;
		display: flex;
		align-items: center;
		position: relative;
		text-decoration: none !important;
		&,
		&:link {
			color: @gray-base;
		}
		&:hover {
			background: @brand-pink;
			color: white;
		}
		&:active {
			background: @brand-pink;
			color: white;
		}
	}
	&--profile-link {
		display: flex;
		@media (min-width: @screen-md-min) {
			display: none;
		}
	}
	&--profile-dropdown {
		display: none;
		@media (min-width: @screen-md-min) {
			display: flex;
		}
	}
}

.header-favourites {
	display: flex;
	position: relative;
	padding: 1rem;
	align-items: center;
	font-size: @font-size-smaller;
	
	&::after {
		content: "|";
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	&__icon {
		position: relative;
		//transition: transform 0.35s;
		.glyphicon {
			font-size: 1.82rem;
			color: @brand-pink;
		}
		&__text {
			position: absolute;
			width: 100%;
			height: auto;
			left: 0;
			top: 0.52rem;
			display: block;
			text-align: center;
			align-items: center;
			font-size: @font-size-smallest;
			color: white;
			line-height: 1;
			font-weight: bolder;
		}
		&--empty {
			.glyphicon {
				color: @gray-dark;
			}
		}
	}

	&--just-added {
		.header-favourites__icon {
			animation-name: header-fav;
			animation-duration: 0.25s;
			animation-iteration-count: 1;
			transform: scale(1);
			@keyframes header-fav {
				0%   {transform: scale(5); opacity: 0.25;}
				100% {transform: scale(1); opacity: 1;}
			}
		}
	}

	&:hover {
		background-color: @brand-pink;
		
		.header-favourites__icon {
			transform: scale(1.25);
			//transition: transform 0.15s;
			.glyphicon {
				color: white;
			}
			&__text {
				color: @brand-pink;
			}
		}
	}
}



.basketinfo {
	font-size: @font-size-smaller;
	display: flex;
	&__item {
		display: flex;
		height: 100%;
	}
	.dropdown-menu {
		//padding: @space-lg @space-base;
		.table-products-container {
			padding: @space-lg 0 0 0;
			max-height: 50vh;
			height: auto;
			max-height: ~"calc(100vh - 300px)";
			overflow-y: auto;
			> .table-products {
				//margin: 0 -@space-base;
				margin: 0 0;
				margin-top: -@space-lg;
				tr:last-child {
					td {
						border-bottom: none !important;
					}
				}
			}
		}
		.basketinfo__footer {
			padding: @space-lg @space-base;
			border-top: 1px solid @gray-lighteer;
			.basketinfo__total {
				font-weight: bold;
				font-size: 1.125rem;
				color: black;
				padding-bottom: @space-lg;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		@media(max-width: @screen-sm-max) {
			.table-products-container {
				max-height: ~"calc(100vh - 240px)";
			}
		}
		@media(max-width: @screen-xs-max) {
			.table-products-container {
				max-height: ~"calc(100vh - 230px)";
			}
		}
		@media(max-width:375px){
			.table-products {
				tr.table-products__item {
					td ~ td {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
					}
					td:last-child {
						padding-right: 1rem;
					}
				}
			}
		}
		@media(max-width: 340px) {
			.table-products {
				tr.table-products__item {
					td.table-products__img {
						img{
							width: 40px;
							height: auto;
						}
					}
				}
			}
		}
		.basketinfo__emptymessage{
			font-size: 1.125rem;
			padding: @space-lg @space-base;
		}
	}
}
.basketinfo__item > a {
	.space-inset-base;
	flex-grow: 2;
	display: flex;
	align-items: center;
	position: relative;
	text-decoration: none !important;
	&,
	&:link {
		color: @gray-base;
	}
	&:hover {
		background: @brand-pink;
		color: white;
	}
	&::before {
		content: "\f110";
		font-family: @font-family-icons;
		font-size: 1.5rem;
		@media (min-width: @screen-md-min) {
			font-size: 2rem;
		}
		display: block;
		margin-right: @space-sm;
		color: @brand-pink;
		transform: translateY(-7%);
	}
	&:hover::before {
		color: white !important;
	}
}

.basketinfo--empty .basketinfo__item a::before {
	color: @gray-dark;
}



.section--navmain {
	.navbar {
		margin: 0 @grid-gutter-width/-2;
		@media (min-width: @screen-md-min) {
			margin: 0;
		}
		display: flex;
		flex-flow: row nowrap;
		position: relative;
		&__nav {
			// width: 100%;
			flex-grow: 1;
			@media (min-width: @screen-md-min) {
				flex-grow: 10;
				//max-width: 60%;
			}
		}
		&__search {
			flex-grow: 1;
			max-width: 40%;
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
			padding-left: @space-base;
			@media (min-width: @screen-md-min) {
				padding-left: 0;
			}
		}
		&.navbar--device-sm {
			@media (min-width: @screen-md-min) {
				display: none;
			}
		}
		&.navbar--device-lg {
			display: none;
			@media (min-width: @screen-md-min) {
				display: flex;
			}
		}
	}
}

.navbar__searchform {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	flex-grow: 0;
	background: @gray-lightest;
	transition: all 0.2s ease-in-out;
	border: 1px solid @gray-lightest;
	border-top: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-light;
	margin: -1px 0;
	&:focus-within {
		border: 1px solid @gray;
		background: white;
		flex-grow: 1;
		transition: all 0.6s ease-in-out;
	}

	.form-control {
		height: auto;
		background: none;
		border: 0;
		flex-grow: 1;
		box-shadow: none !important;
		// max-width: 10rem;
		// max-width: auto;
		text-align: right;
		// text-align: left;
		padding: @space-base @space-sm;
		transition: max-width 0.2s ease-in-out;
		// &:focus {
		// 	max-width: 100%;
		// 	transition: max-width 0.6s ease-in-out;
		// }
		@media(max-width: @screen-sm-max){
			text-align: center;
			// musi platit 2 * verticalPadding + font-size = 3rem
			padding: 0.95rem 0.5rem;
			font-size: 1.1rem;
		}
	}
	.btn {
		border: none;
		flex-grow: 0;
		border-radius: none;
		background: none !important;
		padding: 0 @space-base;
		font-size: @font-size-larger;
		color: @gray-base;
		> .icon {
			&::before {
				vertical-align: middle;
			}
		}
	}
}

// Mobile search form on homepage
.section--navmain {
	.navbar__search--home-sm {
		max-width: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		.navbar__searchform {
			width: 100%;
			max-width: 100%;
			padding: 0;
		}
		input.form-control {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
		}
		@media(min-width:@screen-md-min){
			display: none;
		}
	}
}
