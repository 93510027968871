.body_digital_contents_index {

	.digital-products {
		&__item {
			border-bottom: 1px solid @gray-light;
			//padding: 1rem 0;
			padding-top: 1rem;
			&:first-child {
				border-top: 1px solid @gray-light;
			}
		}
	}
}