@gapWidth: 4rem;
@offcanvasWidth: calc(~"100vw -"@gapWidth);
@transition: .3s ease-in-out;
@nav-level1-item-count: 16;
@min-height: 4rem * @nav-level1-item-count;

.page {
	width: 100%;
	overflow-x: hidden;
}
.page__drawer {
	width: calc(~"200vw - "@gapWidth);
	
	// add Important to override IE hack in Blink Edge
	@supports( filter:blur(4px) ) {
		width: calc(~"200vw - "@gapWidth) !important;
	}
	
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: flex-end;
	transform: translateX(calc(~"-100vw + "@gapWidth));
	will-change: transform;
	@media (min-width: @screen-md-min) {
		transition: none;
	}
	&.active {
		transform: translateX(0);
		will-change: transform;
		@media (min-width: @screen-md-min) {
			transform: translateX(calc(~"-100vw + "@gapWidth));
		}
	}
	&::after {
		content: "";
		position: absolute;
		opacity: 0;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #00304b;
		// background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,1) 100%);
		z-index: @zindex-modal-background;
		pointer-events: none;
		transition: opacity @transition;
	}
}
// pokud je active at nedela scrollbary
.page__drawer.active {
	&::after {
		opacity: 0.5;
		transition: opacity @transition;
		pointer-events: all;
		@media (min-width: @screen-md-min) {
			display: none;
		}
	}
	.page__base {
		// height: 100vh;
		height: @min-height;
		overflow: hidden;
		align-self: flex-start;
	}
	.page__offcanvas {
		height: auto;
		align-self: stretch;
		overflow-y: visible;
	}
	.offcanvas__close {
		&:hover,
		&:focus,
		&:active {
			cursor: pointer;
		}
		&:focus,
		&:active {
			color: @brand-danger;
		}
	}
}

.page__base {
	width: 100vw;
	order: 2;
	position: relative;
}
.page__offcanvas {
	order: 1;
	// at nedela scrollbary
	height: 100vh;
	overflow-y: hidden;
	width: @offcanvasWidth;
	position: relative;
	background: white;
	z-index: @zindex-modal-background + 1;
	
	// override IE hack in Blink Edge
	@supports( filter:blur(4px) ) {
		display: block !important;
	}
}


.hamburger {
	white-space: nowrap;
	display: flex;
	@media (min-width: @screen-md-min) {
		display: none;
	}
	align-items: center;
	&__btn {
		background: none;
		border: none;
	}
	.icon {
		vertical-align: sub;
	}
}

.offcanvas {
	position: relative;
	@media (min-width: @screen-md-min) {
		display: none;
	}
	.nav--primary > .nav__item {
		flex-basis: 100%;
	}
	&__surface {
		position: relative;
		// min-height: 100vh;
		min-height: @min-height;
		overflow-x: hidden;
		overflow-y: visible;
	}
	&__close {
		width: @gapWidth;
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
	}
	&__close-surface {
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		// height: 4.25rem;
		width: @gapWidth;
	}
	&__close-btn {
		border: none;
		background: none;
		padding: 1rem;
	}
	.userinfo {
		min-height: 4.25rem;
		padding-left: @space-xl;
	}
	.navbar__searchform {
		margin: 0;
	}
	.langswitch {
		border-bottom: 1px solid @gray-light;
	}
}


.offcanvas__layer {
	position: absolute;
	min-height: 100vh;
	background: white;
	left: 0;
	top: 0;
	right: 0;
	transform: translateX(0);
	transition: all @transition;
	&--hidden {
		transform: translateX(@offcanvasWidth);
		transition: all @transition;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
	&-header {
		height: 4.25em;
		background: white;
		display: flex;
		align-items: center;
		.btn {
			color: inherit;
		}
	}
	&-body {
		// padding-bottom: @space-xxxl;
	}
}

.offcanvas__layer--1 {
	.offcanvas__layer-body {
		background: @brand-blue-dark;
	}
}

// nastaveni specialnich paddingu
.offcanvas {
	.nav > li > a,
	.nav--level-0 > .nav__item > a,
	.nav--level-1 > .nav__item > a,
	.nav--secondary > .nav__item > a,
	.btn--quicksearch,
	.langswitch,
	.offcanvas__layer-header {
		padding-left: @space-xxl;
	}
}

// HACKS form IE ..chyta to i IE mobile.
*::-ms-backdrop, .page__drawer {
	width: inherit;
}
*::-ms-backdrop, .page__offcanvas {
	display: none;
}
*::-ms-backdrop, .page__drawer::after {
	display: none;
}