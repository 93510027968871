.body_layout_light {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	.section--content {
		h1 {
			margin-bottom: 2rem;
		}
	}
}

.body_product_ordering_initiations_detail {
	.section--content {
		font-size: 1.25rem;
		.logoheader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			img.logo-dumlatek {
				max-width: 250px;
				height: auto;
				order: 2;
				flex-shrink: 0;
			}
			img.logo-velkezeny {
				max-width: 150px;
				height: auto;
				order: 0;
				flex-shrink: 0;
			}
			margin-bottom: 2rem;
			padding-bottom: 2rem;
			border-bottom: 1px dashed @gray-light;
		}
		.product-detail {
			display: flex;
			justify-content: space-between;
			.product-detail-image {
				flex-basis: 33%;
				min-width: 33%;
				padding-right: 2rem;
				flex-grow: 0;
				flex-shrink: 0;
			}
			.product-detail-text {
				flex-grow: 2;
				.cta-container {
					padding-top: 1rem;
					padding-bottom: 4rem;
					border-bottom: 1px dashed @gray-light;
				}
			}
		}
		.btn-cta-velkezeny {
			padding: .5rem 1rem;
			font-size: 1.25rem;
			font-weight: 900;
			line-height: 1.5;
			background-color: #7da649;
			border-color: #7da649;
			border-radius: 0;
			text-transform: uppercase;
			min-width: 350px;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
			&:before {
				display: none;
			}
			&:hover {
				background-color: #698b3d;
				border-color: #628339;
			}
		}
	}
	
	
	
	@media(max-width:@screen-xs-max){
		display: block;
		.section--content {
			.logoheader {
				padding-bottom: 0; 
				display: block;
				img {
					display: block;
					margin: 2rem auto;
				}
			}
			h1 {
				font-size: 2.5rem !important;
			}
			.product-detail {
				display: block;
				.product-detail-image {
					padding: 0 0 1rem 0;
				}
			}
		}
	}
}