.masonry {
	&:extend(.list);
}

html.no-js .masonry {
	&__items {
		display: flex;
		flex-flow: row wrap;
	}
	&__item {
		@media (min-width: @screen-sm-min) {
			flex-basis: 50%;
		}
		@media (min-width: @screen-lg-min) {
			//flex-basis: 1/3 * 100%;
			flex-basis: 25%;
		}
	}
}

html.js .masonry {
	&:extend(.list);
	display: flex;
	flex-flow: row nowrap;
	&__items {
		visibility: hidden;
	}
}

.masonry__col {
	flex-grow: 1;
	@media (min-width: @screen-sm-min) {
		flex-basis: 50%;
	}
	@media (min-width: @screen-lg-min) {
		//flex-basis: 1/3 * 100%;
		flex-basis: 25%;
	}
}
.masonry__col--2 {
	display: none;
	@media (min-width: @screen-sm-min) {
		display: block;
	}
}
.masonry__col--3 {
	display: none;
	@media (min-width: @screen-lg-min) {
		display: block;
	}
}