.order-finish {
	&__qrcode {
		img {
			margin: auto;
		}
	}
	@media(min-width:@screen-sm-min){
		display: flex;
		flex-wrap: wrap;
		margin-left: @grid-gutter-width / -4;
		margin-right: @grid-gutter-width / -4;
		.col {
			padding-left: @grid-gutter-width / 4;
			padding-right: @grid-gutter-width / 4;
		}
		&__text {
			text-align: right;
			padding-top: 0.75rem;
			background-color: fade(white,50%);
		}
		&__qrcode {
			img {
				margin: 0;
			}
		}
	}
}