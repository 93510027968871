.search-suggestions {
	width: 100%;
	//max-width: 1200px;
	max-width: 1640px;
	height: 600px;
	height: auto;
	position: absolute;
	top: 200px;
	z-index: 110;
	overflow-y: auto;
	display: none;
	background-color: white;
	/*@supports(backdrop-filter: blur(5px)){
		background-color: fade(white,97%);
		backdrop-filter: blur(5px);
	}*/
	//box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.4);
	//padding: 1em;
	padding: 0;
	.search-suggestions-list {
		list-style: none;
		padding: 0.5rem;
		margin: 0;
		position: relative;
		z-index: 1;
		& > li {
			padding: 0;
				
			/*border-color: @gray-lighter;
			border-width: 1px;
			border-style: none;*/
			
			.suggestion {
				
				display: flex;
				padding: 1rem;
				height: 100%;
				line-height: 1.2;
				
				&__thumbnail {
					display: block;
					flex-grow: 0;
					position: relative;
					overflow: hidden;
					flex-shrink: 0;
					.suggestion__flags {
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						
						.suggestion__flag {
							position: absolute;
							bottom: 0;
							right: 0;
							&--discount {
								font-size: 12px;
								font-weight: bold;
								padding: 0px 4px 2px 4px;
								background-color: @brand-red;
								color: white;
								&:before {
									content: "";
									position: absolute;
									left: 0;
									bottom: 100%;
									right: 0;
									border-style: solid;
									border-width: 0 0 40px 300px;
									border-color: transparent transparent @brand-red transparent;
								}
							}
						}
					}
					.suggestion__stickers {
						display: block;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						
						.suggestion__sticker {
							position: absolute;
							display: block;
							&--bio {
								right: 3px;
								top: 3px;
								display: block;
								width: 40px;
								img {
									max-width: 100%;
									height: auto;
								}
							}
							&--sustainable {
								right: 3px;
								top: 3px;
								display: block;
								width: 40px;
								img {
									max-width: 100%;
									height: auto;
								}
							}
						}
					}
					.suggestion__icons {
						display: block;
						position: absolute;
						left: 5px;
						top: 5px;
						.card-icon {
							margin-bottom: 0.5rem;
							&--favourite {
								background-color: @brand-red;
								border-radius: 50%;
								width: 1.5rem;
								height: 1.5rem;
								display: flex;
								justify-content: center;
								align-items: center;
								color: white;
								font-size: 0.9em;
							}
						}
					}
				}
				&__text {
					display: block;
					//display: flex;
					padding-left: 0.5rem;
					flex-grow: 10;
					color: @text-color;
					flex-direction: column;
				}
				&__title {
					display: block;
					min-height: 2.4em;
					//margin-bottom: 0.5rem;
					
					flex-grow: 4;
				}
				&__price {
					display: block;
					font-weight: bold;
				}
				&:hover, &:focus {
					text-decoration: none;
					background-color: white;
					outline: none;
					.suggestion__title, .suggestion__price {
						color: @brand-blue-dark;
					}
				}
				@media(max-width:@screen-xs-max){
					&__title {
						font-size: 1.25rem;
					}
				}
				@media(min-width:@screen-md-min){
					position: relative;
					transition: opacity 0.3s ease-in-out;
					&:hover, &:focus {
						transition: opacity 0.3s ease-in-out;
						text-decoration: none;
						background-color: white;
						outline: none;
						.suggestion__title, .suggestion__price {
							color: @brand-blue-dark;
						}

						&:before {
							//outline: 1px solid @brand-blue--sat;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							display: block;
							content: "";
							z-index: 99;
							box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
						}
					}
				}
			}
			//border-bottom-style: solid;
		}
		
		display: flex;
		flex-wrap: wrap;
		& > li {
			flex-basis: 33.3%;
			align-items: stretch;
			&:nth-child(odd) {
				//border-right-style: solid;
			}
			@media(min-width: 1250px) {
				flex-basis: 25%;
			}
		}
		@media(max-width:@screen-sm-max){
			& > li {
				flex-basis: 50%;
			}
		}
		@media(max-width:600px){
			display: block;
		}
		
	}
	
	.suggestions__footer {
		border-top: 1px solid @gray-lighter;
		text-align: center;
		p {
			margin-bottom: 1rem;
		}
		& > *:first-child {
			margin-top: 1rem;
		}
	}
	
	.suggestions__not-found {
		padding: 0.75rem;
		padding-bottom: 0;
		text-align: center;
	}

	.search-suggestions-categories {
		background: rgb(242, 241, 237);
		padding-top: 0.7em;
		padding-bottom: 0.4em;
		position: relative;
		display: flex;
		h4 {
			float: none;
		}
		@media(max-width: @screen-xs-max){
			display: block;
			h4 {
				margin-bottom: 0.25em;
			}
			.search-suggestions-category-list {
				display: block;
				padding-left: 0.75em;
			}
		}
	}

	.search-suggestions-list {
		clear:both;
	}

	.search-suggestions-category-header {
		float: left;
		height: 100%;
		margin-bottom: 0;
		margin-left: 1em;
		margin-right: 0em;
	}

	.search-suggestions-category-list {
		display: inline;
		margin-bottom: 0;
	}

	.search-suggestions-category-list li {
		display: inline-block;
		list-style-type: none;
		margin-left: 0.4em;
		margin-bottom: 0.4em;
		a {
			display: inline-block;
			background-color: white;
			border-radius: 4px;
			padding-left: 0.4em;
			padding-right: 0.4em;
			&:hover {
				box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
				text-decoration: none;
				color: @brand-blue-dark;
			}
			mark {
				background-color: fade(#fee9ed, 50%);
				color: inherit;
				//background-color: #eee;
			}
		}
	}

}
