@import url("./type-tests.less");


//    100    Extra Light or Ultra Light
//    200    Light or Thin
//    300    Book or Demi
//    400    Normal or Regular
//    500    Medium
//    600    Semibold, Demibold
//    700    Bold
//    800    Black, Extra Bold or Heavy
//    900    Extra Black, Fat, Poster or Ultra Black

// https://fonts.google.com/specimen/Grand+Hotel
// https://fonts.google.com/specimen/Pacifico


.text--100 {
	font-weight: @font-weight-extralight;
}
.text--200 {
	font-weight: @font-weight-light;
}
.text--300 {
	font-weight: @font-weight-light;
}
.text--400 {
	font-weight: @font-weight-regular;
}
.text--500 {
	font-weight: 500;
}
.text--600 {
	font-weight: @font-weight-semibold;
}
.text--700 {
	font-weight: @font-weight-bold;
}
.text--800 {
	font-weight: @font-weight-black;
}
.text--900 {
	font-weight: @font-weight-black;
}

.text--large {
	font-size: @font-size-large !important;
}
.text--larger {
	font-size: @font-size-larger !important;
}


// .font-face(@fontname, @fontfile, @fontweight:normal, @fontstyle:normal)

// Pacifico Regular
@font-face {
	.font-face( "pacifico", "../fonts/pacificoregular/pacifico-regular" );
}

//Source Sans Pro
// .font-face-ssp(@fontname-full, @fontname-postscript, @fontfile, @fontweight:normal, @fontstyle:normal)

@font-face {
	.font-face-ssp( "Source Sans Pro ExtraLight", "SourceSansPro-ExtraLight", "SourceSansPro-ExtraLight", 200, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro ExtraLight Italic", "SourceSansPro-ExtraLightItalic", "SourceSansPro-ExtraLightIt", 200, italic );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Light", "SourceSansPro-Light", "SourceSansPro-Light", 300, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Light Italic", "SourceSansPro-LightItalic", "SourceSansPro-LightIt", 300, italic );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Regular", "SourceSansPro-Regular", "SourceSansPro-Regular", 400, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Italic", "SourceSansPro-Italic", "SourceSansPro-It", 400, italic );
}
@font-face {
	.font-face-ssp( "Source Sans Pro SemiBold", "SourceSansPro-SemiBold", "SourceSansPro-Semibold", 600, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro SemiBold Italic", "SourceSansPro-SemiBoldItalic", "SourceSansPro-SemiboldIt", 600, italic );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Bold", "SourceSansPro-Bold", "SourceSansPro-Bold", 700, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Bold Italic", "SourceSansPro-BoldItalic", "SourceSansPro-BoldIt", 700, italic );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Black", "SourceSansPro-Black", "SourceSansPro-Black", 900, normal );
}
@font-face {
	.font-face-ssp( "Source Sans Pro Black Italic", "SourceSansPro-BlackItalic", "SourceSansPro-BlackIt", 900, italic );
}

.text--base {
	display: inline;
	text-transform: none;
	margin: 0;
	padding: 0;
	font-size: @font-size-base;
	font-family: @font-family-base;
	font-weight: @font-weight-regular;
}

.text--red {
	color: @brand-red;
}

.text--nowrap {
	white-space: nowrap;
}

pre {
	font-size: @font-size-smaller;
	.space-inset-base;
	.space-stack-base;
}

.text--white {
	color: white;
	a, a:link, a:visited, a:hover, a:active, a:focus {
		color: white;
	}
}

.text--black {
	color: @gray-base;
	a, a:link, a:visited, a:hover, a:active {
		color: @gray-base;
	}
}

.text-muted {
	color: @gray-dark;
	font-weight: @font-weight-regular;
}

.text--fancy {
	font-family: @font-family-fancy;
	text-transform: none;
}

strong {
	font-weight: @font-weight-bold;
}
small {
	font-size: 80%;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	text-transform: uppercase;
	margin: 0;
	margin-bottom: 1.5em;
	.no-uppercase {
		text-transform: none;
	}
}

h1, .h1, h2, .h2 {
	font-weight: @font-weight-light;
}

// TODO: spravne by to melo byt asi:
// h1 .title .title--fancy
// .title .title--h1 .title--fancy

h1 .text--fancy, .h1 .text--fancy,
h2 .text--fancy, .h2 .text--fancy,
h3 .text--fancy, .h3 .text--fancy {
	text-decoration: none;
}

h1 .text--fancy, .h1 .text--fancy {
	font-size: @font-size-h1-fancy;
}

h2 .text--fancy, .h2 .text--fancy {
	font-size: @font-size-h2-fancy;
}

h3 .text--fancy, .h3 .text--fancy {
	font-size: @font-size-h3-fancy;
}

.title--fancy {
	text-transform: none;
	font-family: @font-family-fancy;
}

.title--h4 {
	text-transform: none;
	font-size: @font-size-h4;
	font-weight: @font-weight-black;
}

.ul--base {
	list-style: none;
	.space-stack-xl;
	> li {
		text-indent: -1em;
		.space-stack-sm;
		&::before {
			text-indent: 0;
			content: "•";
			color: @brand-blue;
			font-size: 1em;
			display: inline-block;
			width: 1em;
		}
	}
}

// Contextual colors
.text-muted {
	color: @text-muted !important;
}
.text-primary {
	.text-emphasis-variant(@brand-primary) !important;
}
.text-success {
	.text-emphasis-variant(@state-success-text) !important;
}
.text-info {
	.text-emphasis-variant(@state-info-text) !important;
}
.text-warning {
	.text-emphasis-variant(@state-warning-text) !important;
}
.text-danger {
	.text-emphasis-variant(@state-danger-text) !important;
}

// Mark tag used for search results highlighing
mark, .mark {
	padding: 0;
	background-color: lighten(@brand-pink--sat, 36%);
}