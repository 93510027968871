.nav {
	&::before,
	&::after {
		content: none;
	} 
}

.nav-tabs {
	.clearfix;
}

@nav-level1-item-width: 20rem; // sirka .nav--level-1 > .nav__item (modry sloupec)

.navbar--device-sm {
	@media (min-width: @screen-md-min) {
		display: none;
	}
}
.navbar--device-lg {
	display: none;
	@media (min-width: @screen-md-min) {
		display: flex;
	}
}

// same as nav--level-0 
.offcanvas .nav--level-1 {
	.list-unstyled;
	display: flex;
	flex-flow: row wrap;
	@media (min-width: @screen-md-min) {
		flex-flow: row nowrap;
	}
	> .nav__item {
		position: static;
		flex-basis: 100%;
		flex-grow: 1;
		font-weight: @font-weight-semibold;
		> a {
			@sidePadding: @space-xl;
			position: relative;
			padding: 0.75rem @sidePadding;
			// fill parent
			display: flex;
			height: 100%;
			align-items: center;
			text-align: left;
			text-decoration: none;
			color: white;
			background: @brand-blue-dark--sat;
			border-bottom: 1px solid @brand-blue-dark;
			border-right: 1px solid @brand-blue-dark;
			&:hover,
			&:focus,
			&:active,
			&[aria-expanded="true"] {
				background: @brand-blue-dark;
			}
			&::after {
				content: "\f363";
				font-family: @font-family-icons;
				position: absolute;
				display: block;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: @sidePadding;
				text-align: center;
			}
		}
	}
}

// almost same as nav--level-0 
.offcanvas .nav--secondary {
	> .nav__item {
		> a {
			padding: 0.75rem @space-xl;
			text-align: left;
			text-decoration: none;
			border-bottom: 1px solid @gray-lighteer;
			border-right: 1px solid @gray-lighteer;
			color: inherit;
			&:hover,
			&:focus,
			&:active{
				color: @brand-blue;
			}
		}
	}
	border: none;
	padding: 0;
}


.nav--primary {
	margin: 0;
	&.nav--level-0 {
		.list-unstyled;
		display: flex;
		flex-flow: row wrap;
		@media (min-width: @screen-md-min) {
			flex-flow: row nowrap;
		}
		> .nav__item {
			position: static;
			flex-basis: 50%;
			flex-grow: 2;
			flex-shrink: 1;
			font-weight: @font-weight-semibold;
			@media (min-width: @screen-md-min) {
				font-size: @font-size-large;
				flex-basis: auto;
			}
			> a {
				@sidePadding: @space-xl;
				position: relative;
				padding: 0.75rem @sidePadding;
				// fill parent
				display: flex;
				height: 100%;
				align-items: center;
				text-align: left;
				text-decoration: none;
				color: white;
				background: @brand-blue-dark--sat;
				border-bottom: 1px solid @brand-blue-dark;
				border-right: 1px solid @brand-blue-dark;
				&:hover,
				&:focus,
				&:active,
				&[aria-expanded="true"] {
					background: @brand-blue-dark;
				}
				&::after {
					content: "\f363";
					font-family: @font-family-icons;
					position: absolute;
					display: block;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: @sidePadding;
					text-align: center;
				}
				.nav__item__image {
						max-height: 24px;
						max-width: 24px;
						margin-left: 0.66ch;
					&:first-child {
						margin-left: 0;
						margin-right: 0.75ch;
					}
				}
				@media (min-width: @screen-md-min) {
					padding: 1rem @space-base;
					color: black;
					display: flex;
					align-items: center;
					justify-content: center;
					background: white;
					border: none;
					text-align: center;
					&:hover,
					&:focus,
					&:active,
					&[aria-expanded="true"] {
						background: @brand-blue-dark--sat;
						color: white;
					}
					&::after {
						content: none;
					}
				}
			}
			&:first-child {
				> a {
					@media (min-width: @screen-md-min) {
						background: @brand-pink;
						color: white;
						&:hover,
						&:focus,
						&:active,
						&[aria-expanded="true"] {
							background: @brand-blue-dark--sat;
							color: white;
						}
					}
				}
			}
			&.discounts {
				background-color: pink;
				> a {
					@media (min-width: @screen-md-min) {
						color: #e42320;
						&:hover {
							color: white;
						}
					}
				}
			}
		}
	}

	.nav--level-1 {
		background: white;
		background: linear-gradient( to right, @brand-blue-dark--sat 0%, @brand-blue-dark--sat 20rem, white 20rem);
		margin: 0;
		padding: 0;
		box-shadow: none;
		border: none;
		border-bottom: 1px solid rgba(0,0,0,0.6);
		border-top: 1px solid rgba(0,0,0,0.25);
		position: absolute;
		left: 0;
		right: 0;
		min-height: 800px;
		> .nav__item {
			width: @nav-level1-item-width;
			background: @brand-blue-dark--sat;
			font-size: @font-size-large;
			font-weight: @font-weight-semibold;
			position: static;
			&:first-child {
				padding-top: @space-lg;
				border-top: 1px solid rgba(0,0,0,0.2);
				margin-top: -1px;
			}
			&:last-child {
				padding-bottom: 2 * @space-xxl;
				// nasledujici padding je kvuli banneru Velke zeny. Az se banner odstrani, melo by jit pryc 
				//padding-bottom: 96px+18px+25px;
			}
			> a {
				// pointer-events: none;
				display: block;
				font-weight: @font-weight-semibold;
				padding: @space-xs @space-xxl;
				&,
				&:hover {
					color: white;
					background: @brand-blue-dark--sat;
				}
				&:focus,
				&:active {
					background: white;
					color: @brand-blue;
					outline: none;
				}
			}
			&--open {
				> a {
					pointer-events: auto;
					&,
					&:hover,
					&:focus,
					&:active,
					&[aria-expanded="true"] {
						background: white;
						color: @brand-blue;
					}
				}
			}
		}
	}

	.nav--level-2 {
		overflow-y: auto;
		overflow-x: hidden; // FIX chrome scrollbar (when ZOOM to 125%)
		columns: 2;
		> .nav__item {
			font-size: @font-size-base;
			font-weight: normal;
			-webkit-column-break-inside: avoid; /* Chrome, Safari */
			break-inside: avoid-column; /* IE11 */
			page-break-inside: avoid;
			> a {
				font-weight: normal;
				color: white;
				padding: @space-xs @space-base;
				display: inline-block; /* IE11 fix zalamovani ve sloupci*/
				width: 100%; /* IE11 fix zalamovani ve sloupci*/
				&,
				&:hover,
				&:focus,
				&:active {
					color: @brand-blue-darker;
					-webkit-column-break-inside: avoid; /* Chrome, Safari */
					break-inside: avoid-column;
					page-break-inside: avoid;
				}
			}
		}
	}
	.nav__panel {
		display: none;
		position: absolute;
		top: 0;
		left: @nav-level1-item-width;
		right: 0;
		background: white;
		height: 100%;
		&-inner {
			height: 100%;
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			align-items: stretch;
			align-content: stretch;
		}
		&-body {
			display: flex;
			flex-flow: row nowrap;
			flex-grow: 2;
			padding: @space-base;
		}
		&-nav {
			flex-basis: 70%;
			align-self: stretch;
		}
		&-banners {
			flex-basis: 30%;
			text-align: center;
			padding: @space-base;
			align-self: stretch;
			border-left: 1px solid @gray-lighter;
			// nize vertical center
			// display: flex;
			// flex-flow: column nowrap;
			// justify-content: center;
		}
		&-footer {
			align-self: flex-end;
			flex-grow: 0;
			background: @gray-lightest;
			padding: @space-base;
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			&-link {
				text-decoration: none !important;
			}
			&-title {
				flex-basis: 70%;
				font-size: @font-size-larger;
				font-weight: @font-weight-bold;
				color: @brand-blue;
				padding: 0 @space-base;
				display: flex;
				align-items: center;
			}
			&-action {
				flex-basis: 30%;
				text-align: center;
				padding: 0 @space-base;
				align-self: stretch;
			}
		}
	}
}

.nav--primary .nav__panel.nav__panel--open {
	display: block;
}
#js--nav-level-1 .nav__item:nth-child(3) .nav__panel {
	// display: block;
}



.nav--secondary {
	padding-bottom: @space-xxl;
	border-bottom: 1px solid @gray-lighter;
	&.nav--level-0 {
		> .nav__item {
			> a {
				text-transform: uppercase;
				color: inherit;
				&:hover,
				&:focus,
				&:active {
					color: @brand-danger;
				}
			}
		}
		> .nav__item.active {
			> a {
				color: @brand-danger;
			}
		}
	}
	.nav--level-1 {
		margin-bottom: @space-lg;
		> .nav__item {
			font-size: @font-size-small;
			margin-bottom: @space-xs;
			> a {
				padding: @space-xs @space-base;
				background: inherit;
				padding-left: 2em;
				text-indent: -1em;
				line-height: 1.25;
				color: @gray-darker;
				&:hover,
				&:focus,
				&:active {
					color: @brand-danger;
					&::before {
						color: @brand-danger;
					}
				}
				&::before {
					text-indent: 0;
					content: "\f363";
					color: @brand-blue;
					font-family: @font-family-icons;
					font-size: 1em;
					// border: 1px solid lime;
					display: inline-block;
					width: 1em;
				}
			}
		}
		> .nav__item.active {
			> a {
				color: @brand-danger;
			}
		}
	}
}
