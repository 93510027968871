// page layout width (=space between banners
@pageWidth: 1264px; // hodnota pro puvodni uzky layout
// @pageWidth: 1704px; // hodnota pro siroky layout
// minimal viewport size to display banners
@bannerBreakpoint: 1300px; // hodnota pro puvodni uzky layout
// @bannerBreakpoint: 2100px; // hodnota pro siroky layout
// top margin
@topMargin: 32px;
// top margin on homepage
@topMarginHP: 24px;

.sidebanner {
  position: absolute;
  display: block;
  top: 234px+@topMargin;
  .body_main_index & {
    top: 573px+@topMarginHP;
  }
  z-index: 1;
  bottom: 0;

  width: ~"calc( ( 100% - @{pageWidth} ) / 2 )";

  overflow-x: clip;


  &__sticky {
    position: sticky;
    display: flex;
    align-items: flex-start;
    top: 0;
    a {
      display: inline-block;
    }
  }
  &--left {
    background-position: top right;
    left: 0;
    .sidebanner__sticky {
      justify-content: flex-end;
    }
  }
  &--right {
    background-position: top left;
    right: 0;
    .sidebanner__sticky {
      justify-content: flex-start;
    }
  }

  @media (max-width: @bannerBreakpoint) {
    display: none !important;
  }
}

// header must have z-index higher than banners to prevent megamenu obscuring by banners
// ancestors must have not overflow hidden to keep position:  sticky working
// some more adjustments needed to prevent horizontal scrollbar
@media (min-width: 1500px){
  .body_main_index {
    .page {
      overflow:initial !important;
      width: 100%;
      .page__drawer {
        overflow:initial !important;
        width: auto !important;
        transform: none !important;
        .page__base {
          width: 100%;          
        }
        .page__offcanvas {
          display: none !important;
        }
      }
    }
  }
}