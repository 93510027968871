.container {
	.container-fixed();
	// max-width: @container-lg;
	max-width: ~"calc(1720px - @{grid-gutter-width})";

	// na homepage ma stranka puvodni uzkou sirku, krome headeru
	.body_main_index & {
		max-width: ~"calc(@{screen-lg} - @{grid-gutter-width})";
	}
	.body_main_index .section--preheader &,
	.body_main_index .section--header &,
	.body_main_index .section--navmain & {
		max-width: ~"calc(1720px - @{grid-gutter-width})";
	}

	// @media (min-width: @screen-sm-min) {
	// 	width: @container-sm;
	// }
	// @media (min-width: @screen-md-min) {
	// 	width: @container-md;
	// }
	// @media (min-width: @screen-lg-min) {
	// 	width: @container-lg;
	// }
}

.flex {
	display: flex;
	flex-flow: row wrap;
}

.flex-row {
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: @grid-gutter-width / -2;
	margin-right: @grid-gutter-width / -2;
}

.col {
	padding-left: @grid-gutter-width / 2;
	padding-right: @grid-gutter-width / 2;
	flex: 1 0 0;
	width: 100%;
	max-width: 100%;
	position: relative;
	min-height: 1px;
}

.col-6 {
	
}