.pdetail {
	form {
		padding: 0;
	}
	&__data {
		order: 2;
		container-name: pdetail_data;
		container-type: inline-size;
		.pdetail__text-info {
			border-top: 1px solid @gray-light;
			padding-top: @space-lg;
			margin-top: @space-lg;
			.pdetail__text-section:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: none;
			}
		}
	}
	&__gallery {
		order: 1;
		flex-basis: 100%;
		border-bottom: 1px solid @gray-lighter;
		.space-stack-xl;
		@media (min-width: @screen-sm-min) {
			flex-basis: 0;
			flex-basis: 50%;
			max-width: 50%;
			border-bottom: none;
		}
		.list--pdetail-gallery {
			margin: 0 @grid-gutter-width / -2;
			@media (min-width: @screen-sm-min) {
				margin: 0;
			}
		}
		.pdetail__text-info {
			border-top: 1px solid @gray-light;
			padding-top: @space-lg;
		}
	}
	&__section {
		border-bottom: 1px solid @gray-light;
		padding-bottom: @space-base;
		margin-bottom: @space-lg;
		hr {
			margin: 0;
		}
		&-title {
			text-transform: none;
			color: @gray-darker;
			margin-bottom: @space-base;
			font-size: @font-size-base;
		}
		.flex-row {
			display: block;
			@media (min-width: @screen-sm-min) {
				display: flex;
			}
		}
	}
	&__header {
		margin-bottom: @space-xl;
	}
	&__title {
		font-size: @font-size-h3;
		text-transform: none;
		font-weight: @font-weight-semibold;
		color: @gray-darker;
		margin-bottom: @space-base;
	}
	&__starrating {
		font-size: @font-size-small;
		.starrating {
			vertical-align: sub;
		}
	}
	&__price {
		flex-grow: 1;
		&-value {
			font-size: @font-size-larger;
			font-weight: @font-weight-bold;
			letter-spacing: -0.03em;
			color: @gray-base;
		}
		&-unit {
			color: @gray-darker;
		}
	}
	&_stockcount {
		font-size: @font-size-larger;
		font-weight: @font-weight-bold;
		text-align: right;
		color: @gray-base;
	}
	&__order {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (min-width: @screen-md-min) {
			// flex-wrap: nowrap;
		}
		&-price {
			display: flex;
			flex-flow: column nowrap;
			white-space: nowrap;
			margin-bottom: @space-lg;
			&-title {
				order: 2;
				font-size: @font-size-smaller;
				color: @gray-darker;
				line-height: 1;
			}
			&-value {
				order: 1;
				font-size: 2rem;
				font-weight: @font-weight-bold;
				line-height: 1;
				letter-spacing: -0.03em;
				flex-grow: 2;
				display: flex;
				align-items: center;
				.currency_main, .price {
					display: inline-block;
					color: @gray-base;
				}
			}
		}
		&-quantity {
			display: flex;
			flex-flow: column nowrap;
			white-space: nowrap;
			margin-bottom: @space-lg;
			.order-quantity-input {
				display: inline-block;
				width: 3em;
				font-size: @font-size-large;
				font-weight: @font-weight-semibold;
			}
			&-title {
				order: 2;
				font-size: @font-size-smaller;
				color: @gray-darker;
				line-height: 1;
			}
			&-value {
				flex-grow: 2;
				display: flex;
				align-items: center;
			}
		}
		&-btn {
			&:extend( .btn all, .btn-lg all, .btn--cta all );
			padding: @space-base @space-lg;
			margin-bottom: @space-lg;
			// margin-bottom: 1em;
			flex-grow: 1;
			flex-basis: 100%;
			@media (min-width: @screen-md-min) {
				flex-basis: auto;
				flex-grow: 0;
			}
		}
	}
	.fav_status {
		display: inline-block;
		margin: 0.5rem 0;
		.glyphicon-heart, .glyphicon-heart-empty {
			color: @brand-red;
			margin-right: 1ch;
		}
	}
	&__notes {
		font-size: @font-size-smaller;
		color: @gray-darker;
		background: @gray-lightest;
		.space-inset-sm;
		padding-left: @space-xxl;
		position: relative;
		p:last-of-type {
			margin-bottom: 0;
		}
		&::before {
			content: "\f445";
			font-family: @font-family-icons;
			font-size: 1.5rem;
			position: absolute;
			left: 0;
			top: 50%;
			text-align: center;
			transform: translateY(-50%);
			width: @space-xxl;
		}
	}
	
	&__watchdog {
		display: flex;
		justify-content: space-between;
		align-items: center;
		& > span.watchdog-ask {
			padding-right: 1rem;
		}
		.btn {
			white-space: nowrap;
		}
		@media(max-width:1000px)and(min-width:768px) {
			display: block;
			& > span.watchdog-ask {
				display: block;
				margin: 0 0 1rem 0;
			}
		}
		@media(max-width:450px) {
			display: block;
			& > span.watchdog-ask {
				display: block;
				margin: 0 0 1rem 0;
			}
		}
	}
	
	ul.nav-tabs, ul.nav-pills {
		margin-bottom: 1rem;
		li {
			a {
				&:focus {
					outline: none;
				}
			}
			&.active {
				a {
					background-color: white;
					color: @text-color;
				}
			}
		}
		
		&.nav-pills {
			clear: both;
			float: none;
			margin-bottom: 0.5rem;
			li {
				display: inline-block;
				float: none;
				margin-bottom: 0.5rem;
				a {
					background-color: @gray-lighteer;
				}
				&.active {
					a {
						background-color: @brand-primary;
						color: white;
					}
				}
			}
		}
		
		& + .tab-content {
			clear: both;
		}
	}
}

// Gallery
.pdetail__image {
	&--main {
		// display: table-cell;
		// width: 500px;
		// height: 500px;
		width: 100%;
		height: auto;
		max-width: 100%;
	}
	&--tbn {
		// display: table-cell;
		width: 100px;
		height: 100px;
		max-width: 100%;
		a {
			display: block;
			height: 100%;
			width: 100%;
		}
	}
}

.pdetail__section--order {
	padding-bottom: 0;
	.pdetail__section-title {
		font-size: @font-size-base;
		margin-bottom: @space-xs;
	}
}

.pdetail__section--usage {
	.pdetail__section-title {
		min-width: 5rem;
		margin: 0;
		display: inline-block;
	}
	.pdetail__section-content {
		font-weight: @font-weight-semibold;
		display: inline;
	}
}

.pdetail__section--desc {
	.col {
		// respozivita: radek sloupecku neni kratsi nez ...
		min-width: 18rem;
	}
}

.pdetail__section--pdetail-variants {
	.col {
		&--pdetail-variants {
			flex-grow: 3;
			padding-right: 0; 
		}
		&--pdetail-mixer {
			flex-grow: 2;
			white-space: nowrap;
			// text-align: right;
			margin-top: 0.75rem;
			// TODO: skryvani MIXERU by melo byt podle toho zda je zarizeni TOUCH, ne podle velikosti
			display: none;
			@media (min-width: @screen-lg-min) {
				display: block;
			}
			&--inlined {
				text-align: right;
				margin-top: 0;
			}
		}
	}
}


.pdetail__price, .pdetail_stockcount {
	align-self: baseline;
}

.starrating {
	font-family: @font-family-icons;
	font-size: @font-size-larger;
	position: relative;
	line-height: 1;
	&::before {
		content: "\f2fc\f2fc\f2fc\f2fc\f2fc";
		display: inline-block;
		color: @gray-light;
	}
	&__rating {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		display: inline-block;
		text-indent: -999em;
		&::after {
			content: "\f2fc\f2fc\f2fc\f2fc\f2fc";
			display: inline-block;
			color: @brand-danger;
			text-indent: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}

.pdetail__care_inst {
	.list--care_instr {
		display: inline-block;
		> .list__item {
			padding-right: @space-sm;
		}
	}
	> a {
		display: inline-block;
		margin-left: auto;
		line-height: 1;
		font-size: @font-size-smaller;
	}
}

.form-group--id_rating .rating-input {
	border: none;
	background: none;
	padding: 0;
	.icon {
		font-size: 2.5 * @font-size-base;
		color: @gray-light;
		position: relative;
		line-height: 1;
		&.active {
			color: @brand-danger;
		}
	}
}

.pdetail-comments__howto {
	color: @gray-darker;
	dt.text-muted {
		font-weight: bold;
	}
	dd {
		.space-stack-base;
	}
}

.section--pdetail-comments {
	.list--comments {
		padding: @space-xl 0;
	}
}

.comment {
	.space-stack-xl;
	padding-bottom: @space-xl;
	font-size: @font-size-small;
	border-bottom: 1px solid @gray-light;
	&__header {
		.space-stack-sm;
		display: flex;
		align-items: center;
		.starrating {
			margin-left: auto;
		}
	}
	&__body {
		line-height: 1.15;
		color: @gray-darker;
	}
}

// TODO: ten toggle je crazy, pocty na radce se meni podle resposivity, je to nejaky slozity...

.pdetail-variants--toggle {
	position: relative;
	// místo na toggle dole
	padding-bottom: @space-xl;
	.list--pdetail-variants {
		max-height: 50rem;
		transition: max-height 1s ease-in-out;
		overflow-y: hidden;
		will-change: transform;
	}
}

.pdetail-variants__size-toggle-input {
	display: none;
}

// rozbaleno
.pdetail-variants__size-toggle-label {
	position: absolute;
	display: none;
	bottom: @space-xs;
	color: @brand-primary;
	&::after {
		content: attr(data-title-less);
	}
	.pdetail-variants--toggle & {
		display: block;
	}
}

// collapsnuto
.pdetail-variants--toggle {
	.pdetail-variants__size-toggle-input:checked {
		~ .pdetail-variants__size-toggle-label {
			&::after {
				content: attr(data-title-more);
			}
		}
		~ .list--pdetail-variants {
			transition: max-height 1s ease-in-out;
			max-height: 14.5rem;
			@media (min-width: @screen-md-min) {
				max-height: 13.25rem;
			}
			will-change: transform;
		}
	}
}

// Mnozstevni slevy
// (např. "Při koupi od 2 m je cena 109 Kč vč. DPH / metr")
.list--quantity-discounts {
	em {
		color: @brand-green;
		font-style: normal;
	}
	.currency_main {
		font-weight: bold;
	}
}

// flags
.pdetail__flag {
	background-color: yellowgreen;
	display: inline-flex;
	align-content: center;
	justify-content: center;
	line-height: 1rem;
	padding: @space-xs 0;
	min-width: 90px;
	color: white;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	font-size: @font-size-smalleer;
	white-space: nowrap;
	text-align: center;
	margin-right: 1rem;
	&--discount {
		background: @brand-red;
		//margin-left: auto;
		flex-direction: column;
		flex-wrap: nowrap;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 100%;
			right: 0;
			border-style: solid;
			border-width: 0 0 12px 90px;
			border-color: transparent transparent @brand-red transparent;
		}
		strong {
			font-size: 2em;
			font-weight: @font-weight-bold;
			letter-spacing: -0.035em;
			display: block;
		}
		span {
			display: block;
		}
	}
}
.pdetail__section.has_flag {
	padding-bottom: 0;
	.flex {
		flex-wrap: wrap;
		align-items: flex-end !important;
		.pdetail__price {
			padding-right: 0.75rem;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			justify-content: flex-start;
			.pdetail__flags {
			}
			.pdetail__price__value {
				display: block;
				padding: 4px 0;
			}
		}
		.pdetail_stockcount {
			align-self: auto;
			display: block;
			padding-bottom: 4px;
			padding-top: 3px;
		}
	}
}

// wide layout adjustments
@media(min-width:1500px) {
	.pdetail {
		&__data {
			margin-left: @space-xxl;
		}
		&__gallery {
			flex-basis: 55%;
			max-width: 55%;
			//border-bottom: none; //1px solid @gray-lighter;
			.js--pdetail__gallery {
				display: flex;
				margin-bottom: @space-lg;
				.list--pdetail-tbns {
					flex-direction: column;
					margin-top: 0;
					padding: 0 @space-lg 0 @space-base;
					padding: 0 0 0 @space-base;
				}
			}
		}
	}
}

// tabulka pro vypis zbytku
.table--scraps {
	// max-width: 60ch;
	width: 100%;
	margin-top: 0.5em;
	tbody {
		tr {
			td {
				padding-bottom: 0.5em !important;
				&.scrap__price {
					text-align: right;
					padding-right: 3em;
				}
				&.scrap__link {
					text-align: right;
					padding-right: 0px;
				}
			}
		}
	}
}

.table--scraps-xs {
	.table--scraps {
		display: block;
		tbody {
			display: block;
			border-top: 1px solid @table-border-color;
			tr {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				border-bottom: 1px solid @table-border-color;
				td {
					&.scrap__name, &.scrap__price {
						flex-basis: 50%;
					}
					&.scrap__price {
						padding-right: 0.5em !important;
					}
					&:last-child {
						flex-basis: 100%;
						text-align: right;
					}
				}
			}
		}
	}
}

@media(max-width: 400px) {
	.table--scraps-xs;
}
@media(min-width: 768px) and (max-width: 920px) {
	.table--scraps-xs;
}
