// Yarriho tuning stylu pro tisk

@media print {

	.header-main {
		.section--navmain, .section--preheader {
			display: none;
		}
		.section--header {
			.hamburger, .userinfo {
				display: none;
			}
		}
	}

	.footer {
		min-height: 0;

		.section--footer {
			border-bottom: none;
		}
	}

}
