.list-inline {
	margin: 0;
	> li {
		padding-left: floor( @grid-gutter-width / 4 );
		padding-right: floor( @grid-gutter-width / 4 );
	}
}

.list {
	margin-bottom: 0;
	&:extend(.list-unstyled);
}

.list-simple {
	padding-left: 2ch;
}

// list with 2 flex columns
.list--base-2 {
	@media (min-width: @screen-md-min) {
		.flex-row;
		// margin-left: @grid-gutter-width / -2;
		// margin-right: @grid-gutter-width / -2;
		.list__item {
			display: flex;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
			flex-basis: 50%;
			max-width: 50%;
			> .card, > .btn {
				flex-grow: 1;
				max-width: 100%;
			}
			> .btn {
				flex-grow: 1;
				margin-bottom: floor(@line-height-computed);
			}
		}
	}
}

// list with 3 flex columns
.list--base-3 {
	.flex-row;
	// margin-left: @grid-gutter-width / -2;
	// margin-right: @grid-gutter-width / -2;
	.list__item {
		display: flex;
		padding-left: @grid-gutter-width / 2;
		padding-right: @grid-gutter-width / 2;
		flex-basis: 100%;
		max-width: 100%;
		> .card {
			flex-grow: 1;
			max-width: 100%;
		}
		> img {
			margin: auto;
		}
	}
	@media (min-width: @screen-sm-min) {
		.flex-row;
		.list__item {
			display: flex;
			flex-basis: 50%;
			max-width: 50%;
		}
	}
	@media (min-width: @screen-md-min) {
		.flex-row;
		.list__item {
			display: flex;
			flex-basis: 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}
}

// list with 4 flex columns
.list--base-4 {
	.flex-row;
	// margin-left: @grid-gutter-width / -2;
	// margin-right: @grid-gutter-width / -2;
	.list__item {
		display: flex;
		padding-left: @grid-gutter-width / 2;
		padding-right: @grid-gutter-width / 2;
		flex-basis: 50%;
		max-width: 50%;
		> .card {
			flex-grow: 1;
			max-width: 100%;
		}
		> img {
			margin: auto;
		}
	}
	@media (min-width: @screen-sm-min) {
		.flex-row;
		// margin-left: @grid-gutter-width / -2;
		// margin-right: @grid-gutter-width / -2;
		.list__item {
			display: flex;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
			flex-basis: 1/3 * 100%;
			max-width: 1/3 * 100%;
			> .card {
				flex-grow: 1;
				max-width: 100%;
			}
			> img {
				margin: auto;
			}
		}
	}
	@media (min-width: @screen-md-min) {
		.flex-row;
		margin-left: @grid-gutter-width / -2;
		margin-right: @grid-gutter-width / -2;
		.list__item {
			display: flex;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
			flex-basis: 25%;
			max-width: 25%;
			> .card {
				flex-grow: 1;
				max-width: 100%;
			}
		}
	}
}

// list with 5 flex columns
.list--base-5 {
	.flex-row;
	// margin-left: @grid-gutter-width / -2;
	// margin-right: @grid-gutter-width / -2;
	.list__item {
		display: flex;
		padding-left: @grid-gutter-width / 2;
		padding-right: @grid-gutter-width / 2;
		flex-basis: 50%;
		max-width: 50%;
		> .card {
			flex-grow: 1;
			max-width: 100%;
		}
		> img {
			margin: auto;
		}
	}
	@media (min-width: @screen-sm-min) {
	}
	@media (min-width: @screen-md-min) {
		.flex-row;
		margin-left: @grid-gutter-width / -2;
		margin-right: @grid-gutter-width / -2;
		.list__item {
			display: flex;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
			flex-basis: 25%;
			max-width: 25%;
			> .card {
				flex-grow: 1;
				max-width: 100%;
			}
		}
	}
	@media (min-width: @screen-lg-min) {
		.flex-row;
		margin-left: @grid-gutter-width / -2;
		margin-right: @grid-gutter-width / -2;
		.list__item {
			display: flex;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
			flex-basis: 20%;
			max-width: 20%;
			> .card {
				flex-grow: 1;
				max-width: 100%;
			}
		}
	}
}

// inline
.list--inline {
	&:extend(.list-inline all);
	display: inline-block;
}

// inline comma separated values
.list--inline-csv {
	&:extend(.list-inline all);
	display: inline-block;
	> .list__item, > li {
		padding: 0;
		&::after {
			content: ", ";
			position: relative;
			left: -0.1em;
		}
		&:last-child::after {
			content: ".";
			position: relative;
			left: -0.1em;
		}
	}
}

// inline dot separated values
.list--inline-dsv {
	&:extend(.list-inline all);
	display: inline-block;
	> .list__item, > li {
		padding: 0;
		&::after {
			content: ". ";
			position: relative;
			left: -0.1em;
		}
	}
}

// inline pipeline separated values
.list--inline-psv {
	&:extend(.list-inline all);
	display: inline-block;
	> .list__item, > li {
		padding: 0;
		&:not(:last-child)::after {
			content: " | ";
			position: relative;
			left: -0.1em;
			color: @brand-blue;
			padding: 0 0.25em;
		}
	}
}

// TODO: z .list--childcats udelat obecny bullets list, a pridat samostane ovladani poctu sloupcu.
.list--childcats {
	columns: 2;
	@media (min-width: @screen-sm-min) {
		columns: 4;
		column-gap: 10px;
	}
	@media (min-width: @screen-md-min) {
		columns: 5;
		column-gap: 5px;
	}
	@media (min-width: 1400px) {
		columns: 6;
	}
	@media (min-width: 1520px) {
		columns: 7;
	}
	font-size: @font-size-small;
	padding-bottom: @space-xl;
	border-bottom: 1px solid @gray-light;
	margin-bottom: @space-md;
	> .list__item {
		padding-left: 1.5em;
		line-height: 1;
		padding-bottom: 0.75em;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		&::before {
			content: "•";
			color: @brand-blue;
			display: inline-block;
			margin-left: -1.5em;
			width: 0.5em;
			vertical-align: top;
		}
		> a {
			display: inline-block;
			&,
			&:link,
			&:visited {
				color: @gray-base;
			}
			&:hover,
			&:active {
				color: @brand-blue-dark;
			}
		}
	}
}

// na strance s vyhledavnim
.body_searches_index .list--childcats {
	columns: 2;
	@media(max-width: @screen-xs-max){
		columns: 1;
	}
	border-bottom: none;
	padding-bottom: @space-md;
	& > .list__item {
		padding-left: 1.5ch;
		padding-bottom: 0.5em;
		line-height: 1.25;
		position: relative;
		&::before {
			/*content: "";
			display: none;*/
			margin: 0;
			display: block;
			position: absolute;
			left: 0;//1.5em;
			top: 0;
		}
	}
}

.list--products {
	margin-left: -0.66rem;//-1rem;
	margin-right: -0.66rem;//-1rem;
	.list__item {
		position: relative;
		margin-bottom: @space-sm;
		@media (min-width: @screen-md-min) {
			margin-bottom: @space-base;
		}
		padding: 0;
		display: flex;
		flex-grow: 1;
		> .card {
			flex-grow: 1;
			max-width: 100%;
		}
		> img {
			margin: auto;
		}
	}
}

.list--pdetail-variants {
	display: flex;
	flex-flow: row wrap;
	.list__item {
		// flex-basis: 20%;
		margin: 0 @space-sm @space-sm 0;
	}
}

.list--stores {
	.list__item {
		padding-bottom: @space-xxl;
		margin-bottom: @space-xxl;
		border-bottom: 1px solid @gray-light;
	}
}

.list--care_instr {
	&:extend(.list--inline);
	.list__item {
		cursor: help;
		img {
			width: 32px;
			height: 32px;
		}
	}
}

.list--pdetail-gallery {
	.list__item {
		display: none;
	}
}

.pdetail__image--active {
	display: block !important;
}

.list--pdetail-tbns {
	display: flex;
	flex-flow: row wrap;
	margin: @space-sm 0;
	.list__item {
		margin-right: @space-sm;
		margin-bottom: @space-sm;
	}
}

.list--location {
	font-weight: initial;
	padding-left: 2.5rem;
	position: relative;
	&::before {
		position: absolute;
		left: 0;
		top: 0;
		content: "\f1ff";
		//vertical-align: middle;
		line-height: 1;
		font-family: @font-family-icons;
		color: @brand-blue;
		font-size: @font-size-h3;
		//padding-right: @space-lg;
	}
}

.list--banners {
	margin-bottom: @space-lg;
	.list__item {
		margin-bottom: @space-lg;
		text-align: center;
		> a {
			display: inline-block;
		}
	}
}

// listy s vypisem produktu do 5ti sloupcu
.body_cards_news,
.body_searches_index,
.body_cards_discounts {
	.list--products {
		&:extend(.list--base-5 all, .list--products all);
	}
}

.list--pager-buttons {
	.space-stack-base;
}

.list--active-filters {
	&:extend(.list--inline);
	padding-top: @space-sm;
	.space-stack-base;
	> .list__item {
		margin-right: @space-sm;
		margin-bottom: @space-sm;
		// TODO: z toho by se dal udelat dalsi typ talcitka (hranate-sedive)
		> a {
			&:extend(.btn-default, .btn-sm);
			display: inline-block;
			border-radius: 0;
			background: @gray-lighter;
			border: 1px solid @gray-lighter;
			&:before {
				content: none;
			}

		}
	}
	.list__item--removeall {
		> a {
			background: white;
			border: 1px solid @gray-light;
		}
	}
}
