// modal

// pri zmensovani viewportu se zobrazeny modal v urcite okamziku ztratil
.modal.in {
	display: block !important;
}

@media (min-width: @screen-md-min) { 
	// vertikalni centrovani modalu
	.modal-open .modal:not(.modal--page,.modal--countdown) {
		display: flex !important;
		align-items: center;
		justify-content: center;
	}
}


.modal-dialog.modal-lg {
	// width: 80%;
	// @media (min-width: @screen-md-min) {
	// 	width: @container-desktop;
	// }
}



.modal-backdrop {
	background: #00304b;
	&.in {
		opacity: 0.95;
	}
}
.modal-content {
	border-radius: 0;
}

.modal__title {
	&:extend(.section__title--lace all);
	margin-bottom: @space-base;
	color: @brand-blue;
	font-size: @font-size-h3-fancy;
}

.modal-title {
	font-weight: 700;
	font-size: 1.125rem;
	line-height: 1;
}

.modal--mixer {
	.modal-body {
		padding: @space-base @space-lg;
	}
}

.modal__body {
	.space-stack-base;
}
.modal__footer {
	border-top: 1px solid @gray-light;
	padding-top: @space-base;
	display: flex;
	align-items: center;
	.info {
		text-align: right;
		color: @gray-darker;
		margin-left: auto;
	}
}

.modal--page {
 
}

.modal--login {
	.modal-body {
		padding: 0;
		background-color: @gray-base;
		position: relative;
	}
	.bootbox-close-button {
		position: absolute;
		top: @space-lg;
		right: @space-lg;
		color: white;
		z-index: 1;
	}
	#form_login_create_new {
		text-align: left;
	}
	.col {
		padding: @space-xxl;
	}
	.login__title {
		text-align: center;
		padding-bottom: @space-lg;
		margin-bottom: @space-lg;
	}
	.col--1 {
		background: white;
		position: relative;
		text-align: center;
		// col--1 ma caret a musi byt pres col--2 (z-index 1)
		z-index: 2;
		.login__title {
			border-bottom: 1px solid @gray-light;
		}
		// caret start
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-width: 20px 20px 0 20px;
			border-color: white transparent transparent transparent;
			@media (min-width: @screen-sm-min) {
				border-width: 20px 0 20px 20px;
				border-color: transparent transparent transparent white;
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
			}
			z-index: 1;
		}
	}
	.col--2 {
		color: white;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		background-color: @gray-darker;
		background-image: url(../images/bg--login.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right center;
		position: relative;
		z-index: 1;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.6);
			z-index: -1;
		}
		.login__title {
			margin-bottom: 0;
			padding: 0;
		}
		.btn{
			&:before {
				content: none !important;
			}
		}
	}
}
