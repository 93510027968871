.form-group--password_strength {
  // Code for form-group and label is not the best but works
  // will need rewrite for Bootstrap 4
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: @grid-gutter-width/8;
  float: none;
  clear: both;
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
    padding-right: @grid-gutter-width / 2;
    font-weight: normal;
  }

  .progress {
    margin-top: 0.25em;
    margin-bottom: 0;
    width: 33.33%;
    flex-basis: 33.333%;
    @media (max-width: @screen-xs-max) {
      width: 50%;
      flex-basis: 50%;
    }
    &-bar {
      // More contrast for % number
      &-warning, &[aria-valuenow="0"] {
        color: #444;
      }
    }
  }
}

html.js {
  .password-input-container {
    input.input--password {
      padding-right: 4ch;
    }
    .password-reveal-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      // final positioning is done by JS
      top: 0;
      width: 4ch;
      height: 100%;
      cursor: pointer;
      .password-reveal-button__hidden {
        display: inline-block;
      }
      .password-reveal-button__visible {
        display: none;
      }
      &.revealed {
        .password-reveal-button__hidden {
          display: none;
        }
        .password-reveal-button__visible {
          display: inline-block;
        }
      }
      // less opacity for icon
      * > * {
        opacity: 0.5;
      }
    }
  }

}

html.no-js {
  .password-reveal-button {
    display: none;
  }
}
