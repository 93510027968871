.cookie_consent_banner_container {
  >div {		
		.rejection-link {
		}

    @ccBreakpoint: 680px;

    & > .container-fluid {
      @media( min-width: @ccBreakpoint ){
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-template-rows: auto auto auto;
        //grid-template-areas: "title   buttons"
        grid-template-areas: "title   title"
                             "text    buttons";
      }
    }

    .cookie_consent_banner__title {
      grid-area: title;
    }

    .cookie_consent_banner__text {
      grid-area: text;
    }

    .cookie_consent_banner__buttons {
      grid-area: buttons;
      text-align: right;
      display: flex;
      @media( min-width: @ccBreakpoint ){
        flex-direction: column;
      }
      align-items: flex-end;
      margin-bottom: 0;
      .btn {
        display: block;
        width: 150px;
        margin-bottom: 0.5rem; 
      }
      .cookie_consent_banner__btn-edit {
        order: 2;
        border: none !important;
        background-color: transparent;
        &:active, &:focus, &:hover {
          background-color: transparent;
        }
      }
      .cookie_consent_banner__btn-accept-all {
        order: 1;
      }
    }

    .cookie_consent_banner__rejection-link {
      text-align: right;
      display: none;
    }

    padding-top: 30px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      //top: -10px;
      top: -5px;
      left: 0;
      width: 100%;
      //height: 10px;
      height: 5px;
      @teethColor: fade(white, 97%);
      background:
        linear-gradient(
          45deg, transparent 33.333%,
          @teethColor, 33.333%, @teethColor, 66.667%,
          transparent 66.667%
        ),
        linear-gradient(
          -45deg, transparent 33.333%,
          @teethColor, 33.333%, @teethColor, 66.667%,
          transparent 66.667%
        );

      @supports( backdrop-filter: blur(10px) ){
        @teethColor: fade(white, 90%);
      background:
        linear-gradient(
          45deg, transparent 33.333%,
          @teethColor, 33.333%, @teethColor, 66.667%,
          transparent 66.667%
        ),
        linear-gradient(
          -45deg, transparent 33.333%,
          @teethColor, 33.333%, @teethColor, 66.667%,
          transparent 66.667%
        );
        background-size: 10px 20px;
        //backdrop-filter: blur(10px);
      }

      //background-size: 20px 40px;
      background-size: 10px 20px;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg, transparent, transparent 50%, @brand-blue 50%, @brand-blue 100%);
      background-size: 12px 1px;
    }

	}
	&::before {
    background-color: fade( #00304b, 30% );
	}
  h3 {
    margin-bottom: 0.5em;
    color: @brand-pink--sat;
    font-family: @font-family-fancy;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.cookie_consent_banner_container > div, #form_cookie_consents_edit {
  .btn {
    &-primary {
      //background-color: @brand-pink !important;
      color: white;
      background-image: none !important;
      &:extend( .btn-danger all);
      &:hover {
        background-color: @brand-pink--sat;
      }
    }
    &-outline-secondary {
      //&:extend(.btn-primary all);
      &:extend(.btn-default all);
      font-weight: bold;

      border-width: 1px;
      border-style: dashed;
      border-color: #307fa5;
      color: #307fa5;
      background-color: #fff;

      &:hover {
        color: #f23d61;
        border-color: #f23d61;
        background-color: #fff;
      }

    }
  }
}