.icon-lg, .icon--lg {
	font-size: 1.5rem;
	vertical-align: middle;
}

.icon-xl, .icon--xl {
	font-size: 2.25rem;
	vertical-align: middle;
}

.icon-xxl, .icon--xxl {
	font-size: 3rem;
	vertical-align: middle;
	&::before {
		// slight vertical alignment correction
		position: relative;
		top: -0.03em;
	}
}

.icon--dark {
	color: @gray-darker;
	&:hover {
		color: @btn-danger-bg-hover;
	}
}

.icon--flashing {
	color: @brand-pink--sat;
	//animation: icon-flashing 0.5s linear 0 infinite;
	animation-name: icon-flashing;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
}

@keyframes icon-flashing {
	0% {
		opacity: 100;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 100;
	}
}

// pokud je ikona soucasti odkazu, nikdy ji nepodtrhavej
a,
a:link,
a:hover,
a:visited,
a:active,
a:focus {
	.icon {
		&,
		&::before,
		&::after {
			text-decoration: none !important;
		}
	}
}