html {
	font-size: 14px;
	@media (min-width: @screen-sm-min) {
		font-size: 16px;
	}
	// '.nojs-only' prvky ktere jsou videt pouze pokud NENI javascript
	&.js .nojs-only {
		/* !important je potreba pri zadavani dodaci a fakturacni adresy behem tvorby objednavky */
		display: none !important;
	}
	// '.js-only' prvky, ktere jsou videt pouze pokud JE javascript
	&.no-js .js-only {
		display: none !important;
	}
}

body {
	color: @gray-darker;
	&.loading * {
		cursor: wait !important;
	}
	&.navActive {
		.page__base::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.4);
			z-index: @zindex-nav-overlay;
		}
	}
}

.tel {
	white-space: nowrap;
}

.dropdown-menu {
	border-radius: 0;
}

body[data-controller="baskets"].body_baskets_empty_basket,
body[data-controller="orders"].body_orders_finish {
	.section--content {
		text-align: center;
		background: url("../images/bg-spulky-konfliky.jpg");
		background-size: cover;
		padding: @space-xxl 0; 
		
		.section__surface {
			max-width: 37rem;
			height: 37rem;
			margin: 0 auto;
			display: flex;
			flex-flow: column nowrap;
			padding: @space-xl;
			justify-content: center;
			background: none;
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255,1) 0%,rgba(255, 255, 255,1) 50%,rgba(255, 255, 255,0) 70%);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
		}
		.section__title {
			font-family: @font-family-fancy;
			color: @brand-danger;
			text-transform: none;
			margin-bottom: 1.5rem;
			font-size: @font-size-h1;
		}
	}
}
body[data-controller="baskets"].body_baskets_empty_basket {
	.section--content {
		filter: gray;
		-webkit-filter: grayscale(1);
	}
}

.ui-icon-gripsmall-diagonal-se {
	@icon-size: 24px;
	width: @icon-size;
	height: @icon-size;
	text-indent: 0;
	display: none;
	&::after {
		font-size: 1em;
		display: block;
		opacity: 0.85;
		content: "";
		// content: "␥◿⟓◲⇲";
		text-indent: 0;
		line-height: 0.25;
		background-image: url(../images/icon--resize-corner.svg);
		background-size: cover;
		width: @icon-size;
		height: @icon-size;
	}
}

.ui-resizable-handle { z-index: auto !important; }


.body_checkouts_user_identification {
	.section--content {
		.col {
			.space-stack-lg;
			.btn, .control-label {
				white-space: nowrap;
			}
		}
	}
}

// dropdowns

.dropdown-menu > li > a {
	color: inherit;
	&:hover,
	&:focus,
	&:active {
		color: @brand-blue;
	}
}


// DISPLAY

.d-sm-none {
	@media (max-width: @screen-sm-max) {
		display: none !important;
	}
}


// Format anchoruuu (at nezabiraji zadne misto)

[id^="anchor--"] {
	// sebrano ze .sr-only
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
	// posun me nahoru
	margin-top: -3rem;
}


// fix jQueryUI naseptavac v modalu (Zasilkovna SK)
.ui-widget.ui-widget-content {
	z-index: @zindex-modal;
}

