.section--superalert {
	padding: 1.375rem 0;
	background-color: @brand-red;
	font-size: 1.375rem;
	color: white;
	font-weight: bold;

	a {
		display: block;
		position: relative;
		padding-right: 2.5em;
		&, &:hover, &:active {
			color: white;
			text-decoration: none;
		}
			&:after {
			content: "\f3d6";
			font-family: Ionicons;
			font-size: 2rem;
			font-weight: 300;
			top: 50%;
			transform: translateY(-50%);
			display: inline-block;
			min-width: 1em;
			text-align: right;
			position: absolute;
			right: 0.625rem;//2rem;
		}
	}
	
	p {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	
	
	
	&--tall {
		padding: 2rem 0;
	}
	
	&--primary {
		background-color: @brand-primary;
	}
	&--danger {
		background-color: @brand-danger;
	}
	&--pink {
		background-color: @brand-pink;
		border-bottom: 1px solid white;
	}
	&--blue {
		background-color: @brand-blue;
		border-bottom: 1px solid white;
	}
}
