.banner-shoproku {
	@media(min-width: 1291px) {
		display: none;
	}
	margin-bottom: 1.5rem;
}


.shoproku-sticky-widget {
	position:absolute;
	top:230px;
	left:-41px;
	z-index:960;
	@media(max-width:1290px){
		display: none;
	}
}
.shoproku-sticky-widget:hover{
	transition:left 0.3s;
	left:0;
}