// Space
// https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62

// $space-inset-default = 16px 16px 16px 16px;
// $space-inset-xs = 4px 4px 4px 4px;
// $space-inset-s = 8px 8px 8px 8px;
// $space-inset-m = 16px 16px 16px 16px;
// $space-inset-l = 32px 32px 32px 32px;
// $space-inset-xl = 64px 64px 64px 64px;
// $space-stack-default = 0 0 16px 0;
// $space-stack-xs = 0 0 4px 0;
// $space-stack-s = 0 0 8px 0;
// $space-stack-m = 0 0 16px 0;
// $space-stack-l = 0 0 32px 0;
// $space-stack-xl = 0 0 64px 0;
// $space-default = 16px;
// $space-xs = 4px;
// $space-s = 8px;
// $space-m = 16px;
// $space-l = 32px;
// $space-xl = 64px;

@space-xs: 0.25rem;
@space-sm: 0.5rem;
@space-base: 1rem;
@space-md: 1.25rem;
@space-lg: 1.5rem;
@space-xl: 2rem;
@space-xxl: 3rem;
@space-xxxl: 5rem;

// insets are padding
// stack are margins

.space-stack-xs() {
	margin: 0 0 @space-xs 0;
}
.space-stack-sm() {
	margin: 0 0 @space-sm 0;
}
.space-stack-base() {
	margin: 0 0 @space-base 0;
}
.space-stack-lg() {
	margin: 0 0 @space-lg 0;
}
.space-stack-xl() {
	margin: 0 0 @space-xl 0;
}
.space-stack-xxl() {
	margin: 0 0 @space-xxl 0;
}
.space-stack-xxxl() {
	margin: 0 0 @space-xxxl 0;
}


.space-inset-xs() {
	padding: @space-xs;
}
.space-inset-sm() {
	padding: @space-sm;
}
.space-inset-base() {
	padding: @space-base;
}
.space-inset-lg() {
	padding: @space-lg;
}
.space-inset-xl() {
	padding: @space-xl;
}


.space-inset-squish-sm() {
	padding: @space-xs @space-base;
}
.space-inset-squish-base() {
	padding: @space-sm @space-base;
}
.space-inset-squish-lg() {
	padding: @space-base @space-xl;
}
.space-inset-squish-xl() {
	padding: @space-lg @space-xl;
}




.space-l--base {
	padding-left: @space-base;
}

.space-r--base {
	padding-right: @space-base;
}