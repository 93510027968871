.swiper-outer {
	position: relative;
	
	.list--products {
		margin: 0;
		align-items: stretch;
		.list__item {
			margin-bottom: 0;
			height: auto;
			// Yarri: u jednoho produktu ve swiperu dochazelo k tomu, ze jeho obrazek vyrostl do sire 100%, omezil jsem to na 240px
			.pcard__image {
				max-width: 240px;
				margin: auto;
				img {
					max-width: 240px;
					margin: 0 auto;
				}
			}
		}
	}

	@nav-btn-width: 3rem;
	
	.swiper-button {
		font-size: 16px;
		position: absolute;
		z-index: 10;
		top: 0;
		bottom: 0;
		width: @nav-btn-width;
		&::after {
			position: absolute;
			line-height: 1;
			width: @nav-btn-width;
			padding: 0 0.25rem;
			height: 1.5rem;
			content: "";
			left: 50%;
			top: 7.5rem;;
			font-family: @font-family-icons;
			font-size: 1.5rem;
			transform: translate(-50%,-50%);
			@media(max-width:600px){
				top: 140px;
			}
		}
		&--prev {
			left: -@nav-btn-width;
			text-align: right;
			&::after {
				content: "\f124";
			}
		}
		&--next {
			right: -@nav-btn-width;
			text-align: left;
			&::after {
				content: "\f125";
			}
		}
		
		&--disabled, &--hidden {
			&:hover, &:focus {
				cursor: auto;
			}
			opacity: 0.25;
		}
		@media(max-width:@screen-md-min){
			//display: none;
		}
		
		&:active, &:focus {
			outline: none !important;
		}
	}
}

.section--youmaywanto {
	@nav-btn-width: 3rem;
	.swiper-outer {
		.swiper-button {
			width: @nav-btn-width;
			&--prev {
				left: -2.25*@nav-btn-width;
			}
			&--next {
				right: -2.25*@nav-btn-width;
			}
		}
	}
}

// Fixes for swiper inside column in card detail 
.pdetail {
	.swiper-outer, .pdetail__data, .swiper-outer .swiper-container { 
		min-width: 0;
		.list--products .list__item .fav_status {
			top: 1rem;
			.glyphicon {
				margin-right: 0.15rem;
				color: white;
			}
			.glyphicon-heart {
				color: white;
			}
		}
		.pcard__title {
			padding-top: 0.75rem;
		}
		.pcard__sticker--bio {
			width: 40px;
		}
		.pcard__flags {
			.pcard__flag--discount {
				flex-basis: 45px;
				padding: 0;
				padding-bottom: 0.15rem;
				strong {
					font-size: 0.85rem;
				}
				span {
					font-size: 0.75rem;
					
				}
			}
		}
		.swiper-button {
			&:after {
				top: 580px/8+5px;
				@media(max-width:1240px) {
					top:~"calc( ( 50vw - 10px ) / 8)";
				}
				@media(max-width:@screen-sm-max) {
					top:~"calc( ( 50vw - 10px ) / 6)";
				}
				@media(max-width:@screen-xs-max) {
					top:~"calc( ( 100vw - 10px ) / 6)";
				}
				@media(max-width:600px) {
					top: 130px;
				}
				@media(min-width:1240px) {
					top:~"calc( ( 50vw - 10px ) / 8)";
				}
				@media(min-width:1500px) {
					top:~"calc( ( 56vw - 10px ) / 8)";
				}
				@media(min-width:1640px) {
					top: 884/8+5px;
				}

			}
		}

		// If there are less items than 4 on large viewport, make them 25% wide
		.swiper-slide:first-child:last-child, // single item
		.swiper-slide:first-child:nth-last-child(2), .swiper-slide:nth-child(2):last-child, // two items only
		.swiper-slide:first-child:nth-last-child(3), .swiper-slide:nth-child(2):nth-last-child(2), .swiper-slide:nth-child(3):last-child // three items only
		{
			@media(min-width: 977px) {
				flex-basis: 25%;
				max-width: 25%;
			}
		}
		// If there are less items than 3 on medium sized viewport, make them 25% wide
		.swiper-slide:first-child:last-child, // single item
		.swiper-slide:first-child:nth-last-child(2), .swiper-slide:nth-child(2):last-child // two items only
		{
			@media(min-width: 601px) and (max-width: 976px) {
				flex-basis: 34%;
				max-width: 34%;
			}
		}
	}
}