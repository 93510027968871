.sdetail {
	&__title {
		font-size: @font-size-h2;
		strong {
			color: @brand-blue;
		}
	}
	&__section {
		.space-stack-base;
		&--description {
			max-height: 10rem;
			overflow-y: hidden;
			text-overflow: ellipsis;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 10rem;
				background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				background-size: auto 4rem;
				background-repeat: repeat-x;
				background-position: left bottom;
				pointer-events: none;
			}
		}
	}
}