.footer {
	min-height: 30rem;
	
	background-color: @brand-blue-dark;
	background-image: url(../images/bg-footer-bluecuts.jpg);
	background-size: cover;
	color: white;

	a {
		&,
		&:hover,
		&:focus,
		&:active,
		&:visited {
			color: white;
		} 
	}
}

.section--footer {
	padding: 1.75rem 0;
}

.section--footer.section--footer-nav {
	padding-bottom: 0;
	@media (min-width: @screen-md-min) {
		padding: 1.75rem 0;
	}
}

// .section--footer-bc {
// 	.flex
// }


.footer-nav {
	margin: 0 -@grid-gutter-width/2;
	@media (min-width: @screen-md-min) {
		display: flex;
		margin: 0;
	}
	&__block {
		flex: 2 0 auto;
	}
}

.footer-nav__block {
	font-weight: @font-weight-bold;
	border-bottom: 1px solid @brand-blue;
	padding: 0 @grid-gutter-width/2;
	padding-bottom: @space-lg;
	margin-bottom: @space-lg;
	&:last-of-type {
		border: none;
		margin: 0;
	}
	@media (min-width: @screen-md-min) {
		padding: 0;
		border: none;
		margin: 0;
	}
	h4 {
		font-weight: @font-weight-light;
	}

	&--shop-with-us {
		flex: 1 0 auto;
		.list--footer-nav {
			columns: 2;
		}
	}
}

.footer {
	[itemprop="telephone"], [itemprop="email"] {
		font-size: @font-size-large;
	}
	[itemprop="openingHours"] {
		padding-top: @space-xs;
		color: @gray;
		display: block;
		line-height: 1;
	}
}


.list--footer-nav {
	.list__item {
		font-weight: @font-weight-bold;
	}
}

.footer__buyvoucher, .footer__conectwithus {
	padding: 0 @grid-gutter-width/2;
	align-self: center;
	flex-basis: auto;
	.title {
		margin: 0;
	}
}

.footer__buyvoucher {
	text-align: center;
	@media (min-width: @screen-md-min) {
		text-align: left;
	}
	flex-grow: 1;
}

.footer__conectwithus {
	flex-grow: 1;
	text-align: center;
	justify-content: center;
	@media (min-width: @screen-md-min) {
		text-align: right;
		justify-content: end;
	}
	display: flex;
	align-items: center;
	&::before {
		@media (min-width: @screen-md-min) {
			content: "|";
			font-size: @font-size-h3;
			margin-right: auto;
			display: block;
			flex: 1 0 auto;
			text-align: left;
		}
	}
	.title {
		display: none;
		margin-right: @space-xl;
		@media (min-width: @screen-md-min) {
			display: block;
		}
		// display: inline-block;
		// vertical-align: middle;
	}
	.list {
		// display: inline-block;
	}
	.list__item {
		display: inline-block;
		margin-left: @space-xl;
		&:first-of-type {
			margin: 0;
		}
	}
}



.section--footer-inpirace {
	height: 12rem;
}


.section--footer-copyright {
	font-size: @font-size-small;
}

.footer__copyright,
.footer__payments {
	padding: 0 @grid-gutter-width/2;
	margin-bottom: @space-base;
}
.footer__copyright {
	order: 2;
	@media (min-width: @screen-md-min) {
		order: 1;
	}
}
.footer__payments {
	order: 1;
	@media (min-width: @screen-md-min) {
		margin-left: auto;
		order: 2;
	}
}

.inspirationcollage {
	text-align: center;
	// transform: translateY(50%);
	// margin-top: -8rem;
	.img-1,
	.img-3 {
		max-width: 30%;
		box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.45);
	}
	.img-2 {
		max-width: 35%;
	}
	.img-1 {
		transform: translateX(10%);
	}
	.img-2 {
		box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.45);
		position: relative;
		z-index: 2;
	}
	.img-3 {
		transform: translateX(-10%);
	}
}