.section--preheader {
	background: @brand-blue;
	color: white;
	font-size: @font-size-smaller;
	padding: 0;
	display: none;
	@media (min-width: @screen-md-min) {
		display: block;
	}
	a {
		color: white;
	}
	.container {
		display: grid;
		grid-template-columns: 3fr 6fr 3fr;
	}
	// prilis dlouhy text v EN verzi
	html[lang="en"] & {
		.container {
			@media(max-width:1200px) {
				grid-template-columns: 3fr 4fr 3fr;
			}
		}
	}
}


.section--navmain {
	@media (min-width: @screen-md-min) {
		border-top: 1px solid @gray-lighter;
		border-bottom: 1px solid @gray-light;
	}
}

.section--bannermain {
	@media (max-width: @screen-sm-max) {
		background-image: none !important;
	}

	// image width same as con
	@media (min-width: @screen-md-min) {
		background-size: 160vw auto;
	}
	@media (min-width: @screen-lg-min) {
		background-size: inherit;
	}
	background-repeat: no-repeat;
	background-position: center top;
	.container {
		position: relative;
		padding: 0;
		@media (min-width: @screen-md-min) {
			padding: 0 @grid-gutter-width/2;
		}
	}
	.banner {
		overflow-x: hidden;
		display: block;
		text-align: center;
		// scale banner placeholder
		@media (min-width: @screen-md-min) {
			height: 33.3333333vw;
		}
		// set banner placeholder height same as banner image height
		@media (min-width: @screen-lg-min) {
			height: 400px;
		}
		&__img {
			.img-responsive;
			@media (min-width: @screen-md-min) {
				visibility: hidden;
				max-height: inherit;
			}
			&--lg {
				display: none;
				@media (min-width: @screen-md-min) {
					display: block;
					display: none;
				}
			}
			&--sm {
				width: 100%;
				@media (min-width: @screen-md-min) {
					display: none;
				}
			}
		}
	}
}

.section--home-selfpromo {
	.container {
		padding-top: @space-lg;
	}
	.list--banners {
		margin: 0 -@grid-gutter-width/2;
		@media (min-width: @screen-md-min) {
			// margin: 0;
		}
	}
}

.section--home-selfpromo,
.section--news,
.section--werecommend,
.section--newslettersubs,
.section-- {
	margin-bottom: @space-xxl;
}

.section--news {
	// BTW na teto sekci je specificky vytekly CTA button
	.list--products {
		margin-bottom: @space-lg;
	}
	.section__footer {
		text-align: center;
		margin-bottom: -3.5em;
	}
}

.section__title {
	&:extend(.text--fancy);
	color: @brand-blue;
	text-align: center;
	margin-bottom: @space-lg;
	font-size: @font-size-h1-fancy / 1.25; // 2rem
	@media (min-width: @screen-sm-min) {
		font-size: @font-size-h1-fancy; // 2.5rem 
	}
}

.section__title--lace {
	&:extend(.text--fancy);
	display: flex;
	justify-content: center;
	&::before, &::after {
		content: "";
		flex: 2 0 auto;
		background: linear-gradient(to right, rgba(48, 127, 165,1) 0%,rgba(48, 127, 165,1) 40%,rgba(48, 127, 165,0) 40%,rgba(48, 127, 165,0) 100%);
		background-size: 20px 2px;
		background-repeat: repeat-x;
		background-position: bottom 1rem left;
	}
	&::before {
		margin-right: @space-base;
	}
	&::after {
		margin-left: @space-base;
	}
}

.section__surface {
	background: white;
	padding: @space-lg 0;
	.section--content & {
		padding: @space-lg;
		padding-bottom: 0;
		margin-bottom: @space-xl;
	}
}

.section__surface--lace {
	@surface-color: white;
	@caret-size: 0.75rem;
	@lace-height: 1.1rem;

	background: @surface-color;
	position: relative;
	margin-bottom: @lace-height;
	box-shadow: 0px @lace-height 8px 0 rgba(0, 0, 0, 0.15);
	&::before, &::after {
		z-index: 1;
	}
	&::before {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%) rotate(360deg);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 @caret-size @caret-size @caret-size;
		border-color: transparent transparent @surface-color transparent;
	}
	&::after {
		@bg1: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 40%,rgba(0,0,0,0) 40%,rgba(0,0,0,0) 100%);
		@bg2: url(../images/bg-lace-bottom.png);
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		height: @lace-height;
		background-image: @bg1, @bg2;
		background-size: 20px 2px, auto;
		background-repeat: repeat-x, repeat-x;
		background-position: top, bottom;
	}
	.container {
		position: relative;
		z-index: 2;
	}
}

.section--guidance-inspiration {
	@media(min-width: @screen-md-min) {
		min-height: 30rem;
		background-position: calc(~"50% - 850px") center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(../images/bg-cary-light.png);
		// nize resi preteceni bgimage do nasledujici sekce
		margin-bottom: -5rem;
		padding-bottom: 5rem;
	}
	@media(min-width: 2200px) {
		background-position: left center, right center;
	}
	.section__surface {
		background-color: inherit;
		padding: 0;
		display: flex;
		.section__content {
			@media (min-width: @screen-md-min) {
				flex-basis: 50%;
			}
			padding: @space-xxl 0 @space-xxxl;
		}
		&::after {
			@media (min-width: @screen-md-min) {
				// obrazek na pozadi pouze na vetsich zarizenich
				content: "";
				display: block;
				flex-basis: 50%;
				background-image: url(../images/banner-footer-inspirace-00.jpg);
				background-position: right center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
	.section__title-alt {
		font-size: @font-size-h1;
		font-weight: @font-weight-extralight;
		line-height: 0.9;
		margin-bottom: 1em;
		.text--fancy {
			font-size: 1em;
			color: @brand-blue;
			font-weight: @font-weight-regular;
		}
	}
}

.section__subtitle {
	font-weight: @font-weight-bold;
}

.section--youmaywanto {
	padding: 3rem 0 5rem;
	.section__surface {
		margin-left: @grid-gutter-width/2;
		margin-right: @grid-gutter-width/2;
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
	}
	.section__title {
		color: @brand-danger;
		text-align: center;
		font-size: @font-size-h2-fancy;
		&::before, &::after {
			content: none;
		}
	}
}

.section--newslettersubs {
	// padding: 4rem 0;
	text-align: center;
	.section__title-alt {
		font-weight: @font-weight-extralight;
		font-size: @font-size-h1;
		text-align: center;
		margin-bottom: @space-base;
	}
	p {
		margin-bottom: @space-xl;
	}
	.input-group {
		max-width: 40rem;
		margin: 0 auto;
	}
}

.section--whyus {
	display: none;
	@media (min-width: @screen-md-min) {
		display: block;
	}
	@bg1-height: 17rem;
	@paperlike-height: 1.625rem;
	padding-top: @space-xxl;
	// pouze na uvodni strance
	.body_main_index & {
		background-image: url(../images/bg-banner-prefooter.png);
		background-repeat: no-repeat;
		background-position: top center;
		background-size: auto @bg1-height;
		// padding-top: @bg1-height/2;
		padding-top: 13rem;
		.section__surface {
			box-shadow: 0 (-0.8 * @paperlike-height) 8px 0 rgba(0, 0, 0, 0.15);
		}
	}
	.section__surface {
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
		position: relative;
		padding: @space-xxxl 0;
		&::before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: @paperlike-height;
			background-image: url(../images/bg-cut-top-paperlike.png);
			background-position: bottom center;
			background-repeat: repeat-x;
		}
	}
	.section__title-alt {
		text-align: center;
		font-size: @font-size-h2;
		font-weight: @font-weight-extralight;
	}
}

.section--footer {
	border-bottom: 1px solid @brand-blue;
}

.section--inspirationcollage {
	display: none;
	@media (min-width: @screen-md-min) {
		display: block;
		background: white;
		height: 5 * @line-height-computed;
	}
}

.section--inspirationcollage + .footer {
	&::before {
		&:extend(.section--footer);
		@media (min-width: @screen-md-min) {
			content: "";
			min-height: 8 * @line-height-computed;
			display: block;
		}
	}
}

.section--breadcrumbs {
	background: @gray-lighteer;
	.section__surface {
		background: none;
		display: flex;
		align-items: center;
		padding: 0.3rem 0;
	}
}

.section--content {
	background: white;
	padding: @space-xl 0;
}

body[data-controller="comments"],
body[data-controller="orders"],
body[data-controller="delivery_addresses"],
body[data-controller="password_recoveries"],
body[data-controller="logins"],
body[data-controller="searches"],
body[data-controller="users"],
body[data-controller="checkouts"],
body[data-controller="baskets"],
body[data-controller="latkobrani_vouchers"]
{
	.section--content {
		background: none;
		padding-top: @space-base;
		h1 {
			.h3;
			font-weight: @font-weight-light;
			margin-bottom: @space-base;
		}
	}
}

body[data-controller="comments"],
body[data-controller="delivery_addresses"],
body[data-controller="password_recoveries"],
body[data-controller="searches"],
body[data-controller="orders"],
body[data-controller="logins"],
body[data-controller="users"] {
	.section--breadcrumbs {
		.section__surface {
			border-bottom: 1px solid @gray-lighter;
		}
	}
}
// stranky bez breadcrumbs, zato s krokama kosiku
body[data-controller="checkouts"],
body[data-controller="orders"][data-action="finish"],
body[data-controller="baskets"] {
	.section--breadcrumbs {
		display: none;
	}
	.section--content {
		padding-top: 0;
	}
}

// stranky bez breadcrumbs a BEZ kroku kosiku potrebuji top padding
body[data-controller="baskets"][data-action="product_added"] {
	.section--breadcrumbs {
		display: block;
	}
}

// stranky s "statickych stranek" a articles
body[data-controller="instructions_and_inspirations"] {
	.section--content {
		padding-bottom: 2*@space-xxxl;
	}
	.section--whyus {
		padding-top: 0;
	}
}
.body_baskets_empty_basket {
	.section--whyus {
		padding-top: 0;
	}
}

.section--pdetail-shareit {
	background: @gray-lightest;
	.section__surface {
		background: transparent;
		padding: @space-xl 0 0;
		text-align: center;
		@media (min-width: @screen-md-min) {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
			max-width: 57em;
			margin: 0 auto;
		}
	}
	.section__title {
		color: @brand-danger;
		margin: 0;
		margin-right: @space-lg;
		// flex-grow: 3;
		flex-shrink: 0;
	}
	.section__teaser {
		flex-shrink: 2;
		margin: 0;
		margin-right: @space-xl;
		// flex-grow: 1;
	}
	.section__btns {
		// flex-grow: 2;
		flex-shrink: 0;
		.btn {
			margin-right: @space-base;
			.icon {
				font-size: 2rem;
				line-height: 0.5;
			}
		}
	}
	.section__title,
	.section__teaser,
	.section__btns .btn {
		margin-bottom: @space-xl;
	}
}

.section--pdetail-shareit,
.section--pdetail-related,
.section--pdetail-comments {
	margin-bottom: @space-xxxl;
	@media(max-width: @screen-sm-max)  {
		form {
			//margin-bottom: @space-xxxl;
			.form__footer {
				margin-bottom: 1rem !important;
			}
		}		
	}
	
}

.section--pdetail-comments {
	.section__subtitle {
		text-align: center;
		border-bottom: 1px solid @gray-light;
		font-weight: @font-weight-light;
		padding: @space-sm 0 @space-lg;
		text-transform: none;
		&--alt {
			text-align: left;
		}
	}
	.col {
		flex-basis: 100%;
		@media (min-width: @screen-md-min) {
			flex-basis: 0;
		}
	}
}

// neco jako form__footer
.section__navigation {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@media (min-width: @screen-md-min) {
		justify-content: flex-end;
	}
	// kvuli bugu v IE 11 https://stackoverflow.com/questions/37534254/flex-auto-margin-not-working-in-ie10-11/37535548#37535548
	// naseldujici kod vidi jen IE
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
		justify-content: inherit;
	}
	.btn--back {
		@media (min-width: @screen-sm-min) {
			margin-right: auto;
		}
	}
}

.section__header {
	&--instructions_and_inspirations {
		border-bottom: 1px solid @gray-lighter;
		margin-bottom: @space-xl;
		padding-top: @space-base;
		.section__title-alt {
			margin-bottom: @space-lg;
		}

	}
}

.section--products {
	.space-stack-xl;
}
